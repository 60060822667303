@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
	margin: 0;
	height: 100dvh;
	overflow: hidden;
	background-color: #0f1729;
}

.scrollable {
	overflow-y: auto;
	max-height: 95vh;
}

.backdrop-blur-sm {
	filter: blur(4px);
}

.mobile-margin {
	margin-bottom: 100px;
}

@media (min-width: 640px) {
	.mobile-margin {
		margin-bottom: 0;
	}
}